import React from "react";

const TheCollection = (props: any) => {
  const { data } = props;
  return (
    <div className="collection-container">
      <div className="toptext">{data?.sub_title}</div>
      <h2 className="headingText">{data?.title}</h2>
      {/* <h2 className="headingText onlyMobile">
        Rarest <br />
        of the rare
      </h2> */}
      <p className="description-text">{data?.content}</p>
    </div>
  );
};

export default TheCollection;
