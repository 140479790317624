import React from "react";
import { formatImageUrl } from "../../../helper/helper";

const TheCask = (props: any) => {
  const { data } = props;

  let bgImage = formatImageUrl(data?.background?.data?.attributes?.url);

  return (
    <div className="cask-container">
      <div className="toptext">{data?.sub_title}</div>
      <div
        className="inner-container"
        // style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="headingText">{data?.title}</h2>

        {data?.content?.length > 0 &&
          data?.content?.map((item: any, index: any) => {
            return (
              <p className="description-text" key={index}>
                {item?.children[0].text}
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default TheCask;
