import React from "react";
import { Col, Row } from "react-bootstrap";

const TheTestingNotes = (props: any) => {
  const { data } = props;
  return (
    <div className="collection-container">
      <div className="toptext">{data?.header}</div>

      <div className="testing_nots_box_section">
        <Row className="row_of_testing_notes">
          {data?.items?.length > 0 &&
            data?.items?.map((item: any, index: any) => {
              return (
                <Col xs={12} md={6} lg={6} xl={3}>
                  <div className="testing_box">
                    {item?.media?.data && (
                      <img src={item?.media?.data?.attributes?.url} alt="img" />
                    )}
                    <h2>{item?.tasting_note?.data?.attributes?.name}</h2>
                    <p>{item?.value}</p>
                  </div>
                </Col>
              );
            })}
          {/*          
          <Col xs={12} md={6} lg={6} xl={3}>
            <div className="testing_box">
              <h2>Taste</h2>
              <p>Vanilla, sweet smoke, fresh fruit and honey, lemon sponge.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div className="testing_box">
              <h2>Palate</h2>
              <p>Vanilla, sweet smoke, fresh fruit and honey, lemon sponge.</p>
            </div>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div className="testing_box">
              <h2>Nose</h2>
              <p>Vanilla, sweet smoke, fresh fruit and honey, lemon sponge.</p>
            </div>
          </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default TheTestingNotes;
