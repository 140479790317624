import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import RadarChart from "../common/radarchat";

const FlavourWheel = (props: any) => {
  const { data } = props;
  return (
    <div className="flovourwheel-container">
      <div className="inner-container">
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <div className="left-innercontainer">
                <h2 className="headingText">{data?.header}</h2>
                <div className="description-text">{data?.content}</div>
                <img
                  src={
                    data?.logo?.data?.attributes?.url
                      ? 
                        data?.logo?.data?.attributes?.url
                      : ""
                  }
                  className="logo"
                />
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="right-innercontainer">
                <RadarChart chartdata={data} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default FlavourWheel;
