import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart = (props: any) => {
  const { chartdata } = props;
  const [radarData, setRadarData]: any = useState({
    labels: [],

    datasets: [
      {
        data: [],
        backgroundColor: "rgba(133, 112, 88, 0.6)",
        borderColor: "rgba(178, 146, 109, 0.6)",
        borderWidth: 0,
        pointBackgroundColor: "transparent",
        pointHoverBorderColor: "#B2926D",
      },
    ],
  });

  useEffect(() => {
    let labels: any = [];
    let values: any = [];

    if (chartdata?.points && chartdata?.points.length > 0) {
      chartdata?.points.map((item: any, index: any) => {
        labels.push(item?.flavour?.data?.attributes?.name.split(" "));
        values.push(item.value);
      });
    }
  
    let json = { ...radarData };
    json["labels"] = labels;
    json["datasets"][0].data = values;
    setRadarData(json);
  }, [chartdata]);

  // const data: any = {
  //   labels: [
  //     ["Peaty/", "Smokey"],
  //     ["Pear/", "Apple"],
  //     ["Grass/", "Citrus"],
  //     ["Floral/", "herbal"],
  //     ["Toffeenanilla"],
  //     ["Nutty/", "Jolly"],
  //     ["Dried ", "Fruits/", "Sherry"],
  //     ["Woody/", "Spicy"],
  //   ],
  //   datasets: [
  //     {
  //       label: [],
  //       data: [0, 2, 2, 2, 1.5, 1.5, 2.5, 1.8],
  //       backgroundColor: "rgba(133, 112, 88, 0.6)",
  //       borderColor: "rgba(178, 146, 109, 0.6)",
  //       borderWidth: 5,
  //       pointBackgroundColor: "transparent",
  //       pointHoverBorderColor: "#B2926D",
  //     },
  //   ],
  // };

  const chartRef: any = React.createRef();

  const radarOptions = {
    startAngle: 0,
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        ticks: {
          display: false,
          tickLength: 7,
        },
        pointStyle: false,
        pointLabels: {
          color: "hsla(30, 5%, 7%, 1)",
          fontWidth: "400",
          font: {
            size: 15,

          },
        },
        grid: {
          circular: true,
          color: "#B2926D",
        },
        anglelines: {
          display: false,
          // lin
        },
        beginAtZero: true,
        max: 8,
        min: 0,
      },
    },
  };

  return (
    <div className="radar_chart_div">
      <Radar
        ref={chartRef}
        data={radarData}
        redraw={true}
        options={radarOptions}
      />
    </div>
  );
};

export default RadarChart;
