import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Button from "../common/CommonButton/Button";
import Select from "react-select";
import { ReactSVG } from "react-svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BuyNowCart from "../common/BuyNowCart";
import {
  setAddVariantToCart,
  setUpdateQuantityInCart,
  setRemoveLineItemInCart,
  getProductDetails,
} from "../../util/utils.js";
import Link from "next/link";

const options: any = [{ value: "United Kingdom", label: "United Kingdom" }];

const ProductPortEllenFold = (props: any) => {
  const { data, countryCode } = props;

  const [selectedOption, setSelectedOption] = useState(null);
  const [openModal, setOpenModal]: any = React.useState(false);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [stock, setStock] = useState(false);
  const [showAddToCart, setShowAddToCart] = useState(false);

  var settings = {
    // dots: false,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // autoplay: true,
    // arrows: true,
    dots: false,
    infinite: data?.images?.data?.length > 1,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: data?.images?.data?.length > 1,
    arrows: true,
  };

  const addToCart = (data: any) => {
    const productId = `gid://shopify/ProductVariant/${data.shopify_id}`;
    const lineItemsToAdd = [
      {
        variantId: productId,
        quantity: 1,
      },
    ];

    if (countryCode) {
      setAddVariantToCart(lineItemsToAdd, countryCode).then((res) => {
        setCheckout({
          ...res,
        });
        const response = { ...res };

        props?.updateCheckout && props.updateCheckout(response);
        toggleCartSidebar();
      });
    }
  };

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
      const response = { ...res };

      props?.updateCheckout && props.updateCheckout(response);
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
      const response = { ...res };

      props?.updateCheckout && props.updateCheckout(response);
    });
  };

  const toggleCartSidebar = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (countryCode === "en-GB" || countryCode === "en-US") {
      setShowAddToCart(true);
    }
    let shopifyProductID = data?.shopifyproduct_id;
    let productID = `gid://shopify/Product/${shopifyProductID}`;

    getProductDetails(productID, countryCode).then((productDetails) => {
      setStock(productDetails?.availableForSale);
    });
  }, [data]);

  return (
    <div className="firstfold-container">
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <ReactSVG
        src="/product/left-arrow.svg"
        className="onlyMobile backarrow"
      />
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} className="onlyDesktop">
            <div className="left-innerwrapper">
              {data?.feature_image?.data ? (
                <img
                  src={data?.feature_image?.data?.attributes?.url}
                  className="imgClass"
                />
              ) : null}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div className="right-outerwrapper">
              <div className="right-innerwrapper">
                <div className="top-headingtext">
                  {data?.collection && data?.collection}
                </div>
                <h1 className="headingText">{data?.name}</h1>
                <div className="product_details_abv_section">
                  <div className="product_details_box">
                    <h3>ABV:</h3>
                    <h3>{data?.abv}</h3>
                  </div>
                  <div className="vertical_line"></div>
                  <div className="product_details_box">
                    <h3>volume:</h3>
                    <h3>{data?.volume}</h3>
                  </div>
                  <div className="vertical_line"></div>
                  {data?.cask_number && (
                    <div className="product_details_box">
                      <h3>Cask no:</h3>
                      <h3>{data?.cask_number}</h3>
                    </div>
                  )}
                </div>

                <div className="product_sort_description onlyDesktop">
                  <p>{data?.description}</p>
                  <Link href={"#details"}>
                    <button className="read_more_btn">Read More</button>
                  </Link>
                </div>

                <div className="ex_wait_price_section onlyDesktop">
                  <h3>EX VAT Price:</h3>
                  {/* £ */}
                  <h3>{data?.price}</h3>
                </div>

                {/* <p className="years-text">{data?.age}</p>
                {data?.item_serial && (
                  <div className="number-container">
                    <span>{data?.item_serial}</span>
                  </div>
                )} */}

                <div className="left-innerwrapper onlyMobile">
                  {data?.feature_image?.data ? (
                    <img
                      src={data?.feature_image?.data?.attributes?.url}
                      className="imgClass"
                    />
                  ) : null}
                </div>

                <div className="ex_wait_price_section onlyMobile">
                  <h3>EX VAT Price:</h3>

                  <h3>{data?.price}</h3>
                </div>

                <div className="product_sort_description onlyMobile">
                  <p>{data?.description}</p>
                  <Link href={"#details"}>
                    <button className="read_more_btn">Read More</button>
                  </Link>
                  {/* <button className="read_more_btn">Read More</button> */}
                </div>

                {/* <div className="productslider-container onlyMobile">
                  <Slider {...settings}>
                    {data?.images &&
                      data?.images?.data?.map((item: any, index: any) => {
                        return (
                          <div tabIndex={index}>
                            <img
                              src={
                                process.env.NEXT_PUBLIC_DUNCAN_TAYLOR_API_URL +
                                item?.attributes.url
                              }
                              className="sliderimg"
                            />
                          </div>
                        );
                      })}
                  </Slider>
                </div> */}

                {/* have to change this button  */}
                {/* {stock &&
                data?.shopifyproduct_id &&
                data?.shopify_id &&
                showAddToCart ? (
                  <button
                    className="learnmorebtn "
                    onClick={() => addToCart(data)}
                  >
                    BUY NOW
                  </button>
                ) : !stock ? (
                  <button
                    className="learnmorebtn btn_disabled"
                    // onClick={() => addToCart(data)}
                  >
                    NOT AVAILABLE
                  </button>
                ) : null} */}

                {/* <Button btnText={"Buy now"} btnClass="learnmorebtn " /> */}

                {/* <div className="delivery-to-wrapper">
                  <p className="text">We are delivering to:</p>
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  />
                </div> */}
                {/* <div className="accordion-wrapper">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Description</Accordion.Header>
                      <Accordion.Body>{data?.description}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductPortEllenFold;
