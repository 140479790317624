"use client";
import BuyNowCart from "@/components/common/BuyNowCart";
import SignupForFree from "@/components/landingpage/SignupForFree";
import NewMainLayout from "@/components/layout/newMainLayout";
import Collection from "@/components/rarestproduct/Collection";
import FlavourWheel from "@/components/rarestproduct/FlavourWheel";
import ProductPortEllenFold from "@/components/rarestproduct/ProductPortEllenFold";
import SliderSection from "@/components/rarestproduct/SliderSection";
import TestingNote from "@/components/rarestproduct/TestingNote";
import TheCask from "@/components/rarestproduct/TheCask";
import TheCollection from "@/components/rarestproduct/TheCollection";
import TheOctaveProductDetails from "@/components/rarestproduct/TheOctaveProductDetails";
import TheTestingNotes from "@/components/rarestproduct/TheTestingNotes";
import ScoMeta from "@/components/scometa";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
import React, { useEffect, useState } from "react";

const ProductDetails = (props: any) => {
  const { data, meta, countryCode } = props;

  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = React.useState(false);
  const [productDetailsData, setproductDetailsData]: any = useState([]);

  const updateCheckout = (res: any) => {
    setCheckout({ ...res });
  };

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  useEffect(() => {
    if (data) {
      setproductDetailsData(data?.data?.attributes);
    }
  }, [data]);

  const renderProductDetailsPage = () => {
    return (
      <>
        {productDetailsData?.blocks && productDetailsData?.blocks.length
          ? productDetailsData?.blocks.map((content: any, index: any) => {
              switch (content.__component) {
                case "product.info-section":
                  return (
                    <div id="details">
                      <TheCollection key={content?.id + index} data={content} />
                    </div>
                  );

                case "product.tasting-octave":
                  return (
                    <>
                      {content?.is_active === true && (
                        <TheTestingNotes
                          key={content?.id + index}
                          data={content}
                        />
                      )}
                    </>
                  );
                case "product.tasting-notes":
                  return (
                    <>
                      {content?.is_active === true && (
                        <TheTestingNotes
                          key={content?.id + index}
                          data={content}
                        />
                        // <TestingNote key={content?.id + index} data={content} />
                      )}
                    </>
                  );

                case "product.flavour-wheel":
                  return (
                    <FlavourWheel key={content?.id + index} data={content} />
                  );

                case "product.info-section2":
                  return (
                    <>
                      {content?.is_active === true && (
                        <TheCask key={content?.id + index} data={content} />
                      )}
                    </>
                  );
                case "product.product-detail":
                  return (
                    <TheOctaveProductDetails
                      key={content?.id + index}
                      data={content}
                    />
                  );

                case "product.discover-more":
                  return (
                    <Collection
                      key={content?.id + index}
                      data={content}
                      countryCode={countryCode}
                    />
                  );
                case "product.gallery":
                  return (
                    <>
                      {content?.is_active === true && (
                        <SliderSection
                          key={content?.id + index}
                          data={content}
                        />
                      )}
                    </>
                  );
                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };

  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      {productDetailsData && (
        <NewMainLayout
          checkout={checkout}
          toggleCartSidebar={toggleCartSidebar}
        >
          <div className="product-container">
            <ProductPortEllenFold
              data={productDetailsData}
              countryCode={countryCode}
              updateCheckout={updateCheckout}
            />
            {productDetailsData && renderProductDetailsPage()}

            <SignupForFree />
          </div>
        </NewMainLayout>
      )}
    </>
  );
};

export default ProductDetails;
