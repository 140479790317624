import React from "react";
import { Col, Row } from "react-bootstrap";

const TheOctaveProductDetails = (props: any) => {
  const { data } = props;
  return (
    <div className="collection-container">
      <div className="toptext">{data?.title}</div>

      <div className="octave_product_details_section">
        <Row className="octave_product_gap">
          {data?.items?.map((item: any, index: number) => {
            return (
              <Col xs={12} md={6} key={index}>
                <Row>
                  <Col xs={5} md={4}>
                    <h2>{item?.title}</h2>
                  </Col>
                  <Col xs={7} md={8}>
                    <h3>{item?.details}</h3>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default TheOctaveProductDetails;
