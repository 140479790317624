import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../common/CommonButton/Button";

const Collection = (props: any) => {
  const { data, countryCode } = props;

  const ProductCard = React.memo((props: any) => {
    const [stock, setStock] = useState(false);
    const [price, setPrice] = useState();
    const [showAddToCart, setShowAddToCart] = useState(false);

    // useEffect(() => {
    //   const path = window.location.pathname;
    //   const parts = path.split("/");
    //   const findCountry = parts[1];
    //   if (findCountry === "en-GB") {
    //     setShowAddToCart(true);
    //   }
    //   let shopifyProductID = props?.item?.attributes?.shopifyproduct_id;
    //   let productID = `gid://shopify/Product/${shopifyProductID}`;

    //   getProductDetails(productID).then((productDetails) => {
    //     setStock(productDetails?.availableForSale);
    //     setPrice(productDetails?.variants[0]?.price?.amount);
    //   });
    // }, []);

    return (
      <>
        <div className="product_card" key={props?.index}>
          <div className="product_upper_section">
            <div className="product_img_section">
              <img
                src={
                  props?.item?.attributes?.feature_image?.data?.attributes?.url
                }
                alt={`Duncan Taylor Whisky`}
                className="bottle_img"
              />
            </div>
            <div className="product_contains">
              <h2>{props?.item?.attributes?.name}</h2>
              {price && (
                <h6>{`${countryCode === "en-US" ? "$" : "£"} ${price}`}</h6>
              )}
            </div>
          </div>
          <div className="product_cart_section">
            <button
              className="btn_buy_noe"
              // onClick={() => addToCart(props?.item)}
            >
              BUY NOW
            </button>
          </div>
        </div>
      </>
    );
  });
  return (
    <>
      <div className="shop-now-wrapper collection_wrapper">
        <div className="shop_now_main_container">
          <div className="shop_now_product_cards">
            <div className="shop_now_title">{data?.header}</div>
            {/* <div className="product_counts">Showing 12 of 100</div> */}

            <div className="product_card_wrapper">
              {data?.products?.data &&
                data?.products?.data.map((item: any, index: number) => {
                  return (
                    <div className="product_card" key={index}>
                      <div className="product_upper_section">
                        <div className="product_img_section">
                          <img
                            src={
                              item?.attributes?.feature_image?.data?.attributes
                                ?.url
                                ? item?.attributes?.feature_image?.data
                                    ?.attributes?.url
                                : ""
                            }
                            alt={`Duncan Taylor Whisky`}
                            className="bottle_img"
                          />
                        </div>
                        <div className="product_contains">
                          <h2>{item?.attributes?.name}</h2>
                          {item?.attributes?.price && (
                            <h6>{`EX VAT Price: ${item?.attributes?.price}`}</h6>
                          )}
                        </div>
                      </div>
                      <div className="product_cart_section">
                        {item?.attributes?.cta?.link && (
                          <Button
                            btnText={item?.attributes?.cta?.title}
                            btnClass={"btn_buy_noe"}
                            btnLink={item?.attributes?.cta?.link}
                            linkOpenState={
                              item?.cta?.attributes?.open_in_new_tab
                            }
                          />
                        )}
                        {/* <button
                        className="btn_buy_noe"
                      // onClick={() => addToCart(props?.item)}
                      >
                        View More
                      </button> */}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="discover-collection-container">
        <Container>
          <Row>
            <Col md={12} xs={12}>
              <div className="headingText">{data?.header}</div>
            </Col>
          </Row>
          <Row>
            {data?.products?.data &&
              data?.products?.data?.map((item: any, index: any) => {
                return (
                  <Col md={4} xs={12} key={index}>
                    <div className="card-container">
                      <div className="bottle-wrapper">
                        <img
                          src={
                            item?.attributes?.feature_image?.data?.attributes?.url
                              ? item?.attributes?.feature_image?.data?.attributes
                                ?.url
                              : ""
                          }
                          className="image"
                        />
                      </div>
                      <h3 className="card-heading">{item?.attributes?.name}</h3>
                      <p className="card-subheading-text">
                        {item?.attributes?.collection}
                      </p>
                      {item?.attributes?.cta?.link && (
                        <Button
                          btnText={item?.attributes?.cta?.title}
                          btnClass={"learnmorebtn"}
                          btnLink={item?.attributes?.cta?.link}
                          linkOpenState={item?.cta?.attributes?.open_in_new_tab}
                        />
                      )}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </div> */}
    </>
  );
};

export default Collection;
